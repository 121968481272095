export const IDEAS_STATE_COLORS = {
  SUGGESTED: {
    BG: "#fbc02d",
    COLOR: "#000000",
  },
  ADJUSTMENT: {
    BG: "#78909c",
    COLOR: "#000000",
  },
  EVALUATED: {
    BG: "#2196f3",
    COLOR: "#000000",
  },
  IMPLEMENTING: {
    BG: "#00bcd4",
    COLOR: "#000000",
  },
  IMPLEMENTED: {
    BG: "#4caf50",
    COLOR: "#000000",
  },
  REJECTED: {
    BG: "#f44336",
    COLOR: "#000000",
  },
};
