import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import navigation from "./navigation";
import AccountSwitcher from "./accountSwitcher";
import { MenuList } from "./navigationList";
import { Grid, Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useMatchMobile } from "shared/hooks/responsive";
import { useTranslate } from "shared/components/translate";
import { logout } from "reducers/ducks/auth/logout";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

function SideBar({ handleDrawerClose, handleButtonDrawerClose }) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const match = useMatchMobile();
  const tanslate = useTranslate();
  return (
    <React.Fragment>
      <Grid container alignItems="space-between" className="h-100">
        <Grid item xs={12}>
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                  <ChevronRightIcon />
                )}
            </IconButton>
          </div>
          <Divider />
          <AccountSwitcher handleDrawerClose={handleButtonDrawerClose} />
          <Divider />
          <List>
            <MenuList
              menuItems={navigation}
              handleDrawerClose={handleButtonDrawerClose}
            />
          </List>
        </Grid>
        {!!match && (
          <Grid item xs={12}>
            <Grid container alignItems="flex-end" className="h-100">
              <Grid item xs={12}>
                <div style={{ padding: 8 }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      dispatch(logout());
                    }}
                  >
                    {tanslate("auth.logout")}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}

export default SideBar;
