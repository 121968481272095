import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import IdeaForm from "../shared/ideaForm";
import { createIdeaApi } from "reducers/ducks/idea/createIdea";
import CommissionVerdict from "./commission";
import { getIdea } from "reducers/ducks/idea/getIdea";
import { IDEAS_STATE } from "common/ideasState";
import { getUserId, isUserInCommission } from "selectors/user.selector";
import { changeIdeaState } from "reducers/ducks/idea/changeIdeaState";
import { listIdeas } from "reducers/ducks/idea/listIdeas";
import { pathEq } from "ramda";
import { updateIdeaApi } from "reducers/ducks/idea/updateIdea";
import Assignee from "./assignee";
import IdeaComments from "./comment";
import selectUser from "shared/components/select-user";
import { ideaValidationSchema } from "../shared/ideaForm/validation";
import DialogTitle from "shared/components/dialog/dialogTitle";

const EditIdea = ({ open, setOpen, selectedIdea }) => {
  const dispatch = useDispatch();
  const [fetchedIdea, setFetchedIdea] = useState({});
  const id = selectedIdea?.id;
  const isSuggested = fetchedIdea?.state === IDEAS_STATE.SUGGESTED;
  const isMember = useSelector(isUserInCommission);
  const userId = useSelector(getUserId);
  const isEnableEditForm =
    pathEq(["createdBy", "id"], userId, fetchedIdea) &&
    fetchedIdea?.state === IDEAS_STATE.ADJUSTMENT;
  useEffect(() => {
    if (!!id) {
      dispatch(getIdea(id)).then((response) => setFetchedIdea(response.data));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (isSuggested && isMember && id) {
      dispatch(changeIdeaState(id, IDEAS_STATE.EVALUATED))
        .then(() => dispatch(getIdea(id)))
        .then((response) => setFetchedIdea(response.data))
        .then(() => dispatch(listIdeas()));
    }
  }, [dispatch, setFetchedIdea, isSuggested, isMember, id]);

  if (!selectedIdea) {
    return null;
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle onClose={() => setOpen(false)}>Peržiūrėti idėją</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Formik
              initialValues={fetchedIdea}
              enableReinitialize
              validationSchema={ideaValidationSchema}
              onSubmit={(values) => {
                dispatch(updateIdeaApi(id, values))
                  .then(() => dispatch(listIdeas()))
                  .then(() => setOpen(false));
              }}
            >
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <IdeaForm disabled={!isEnableEditForm} />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="createdBy"
                          component={selectUser}
                          isDisabled
                          disabled
                          label="Idėją pateikęs naudotojas"
                        />
                      </Grid>
                      <Field name="state">
                        {({ field: { value } }) => {
                          if ([IDEAS_STATE.IMPLEMENTING, IDEAS_STATE.IMPLEMENTED].indexOf(value) >= 0) {
                            return (
                              <Grid item xs={12}>
                                <Field
                                  isDisabled
                                  name="assignee"
                                  label="Atsakingas už idėjos įgyvendinimą asmuo"
                                  component={selectUser}
                                />
                              </Grid>
                            )
                          }

                          return null
                        }}
                      </Field>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    {!!isEnableEditForm && (
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Button
                            fullWidth
                            color="secondary"
                            variant="contained"
                            onClick={() => setOpen(false)}
                          >
                            Atšaukti
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            type="submit"
                            fullWidth
                            color="primary"
                            variant="contained"
                          >
                            Redaguoti
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Form>
            </Formik>
          </Grid>

          <Grid item xs={12}>
            <CommissionVerdict
              closeModal={() => setOpen(false)}
              idea={selectedIdea}
            />
          </Grid>
          <Grid item xs={12}>
            <Assignee closeModal={() => setOpen(false)} idea={selectedIdea} />
          </Grid>
          <Grid item xs={12}>
            <IdeaComments idea={selectedIdea} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EditIdea;
