const SET_FILTER = 'MYKPI/SEARCH/SET_FILTER';


const createFilterReducer = defaultState => (state = defaultState, { type, payload } = {}) => {
  switch (type) {
    case SET_FILTER:
      return payload;
    default:
      return state;
  }
}

export const createSetFilter = name => filter => ({
  name,
  type: SET_FILTER,
  payload: filter
});

export default createFilterReducer