export const getIdeaComments = (id, comment) => (
  dispatch,
  getState,
  { api }
) => {
  return api.get(`/private/ideaComment/general/ideaId/${id}`);
};

export const getCommissionIdeaComments = (id, comment) => (
  dispatch,
  getState,
  { api }
) => {
  return api.get(`/private/ideaComment/commission/ideaId/${id}`);
};
