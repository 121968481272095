import React from 'react'
import { withRouter } from 'react-router-dom';
import { Card, CardHeader, CardContent, Grid, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import InputField from '../../shared/components/input';
import { withTranslate } from '../../shared/components/translate';
import { remindPassword } from 'services/auth.service';

const ForgotPassword = withRouter(({ history, translate }) => {
  const dispatch = useDispatch();
  return (
    <Card>
      <CardHeader title={translate('auth.forgotPassword.title')} />
      <CardContent >
        <Formik
          validationSchema={Yup.object().shape({
            email: Yup.string().required().email().required(),
          })}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            dispatch(remindPassword(values))
              .then(() => history.push('/auth/forgotPassword/success'))
              .catch(() => { actions.setError({ failed: true }) })
              .finally(() => {
                actions.setSubmitting(false);
              });
          }}
          render={props => (
            <form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} >
                  <Field
                    fullWidth
                    component={InputField}
                    label={translate('common.email')}
                    variant='outlined'
                    type='text'
                    name='email'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    color='primary'
                    variant='contained'
                    type='submit'
                    disabled={props.isSubmitting || !props.isValid}>
                    {translate('common.send')}
                  </Button>
                </Grid >
              </Grid>
            </form>
          )
          }
        />
      </CardContent>
    </Card>
  );
})

export default withTranslate(ForgotPassword);