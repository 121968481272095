import React from "react";
import { Card, CardContent, CardHeader, Typography } from "@material-ui/core";
import { take } from "ramda";
import { IDEAS_STATE_COLORS } from "shared/enums/ideasStateColors";

const IdeaCard = ({ setSelectedIdea, ...idea }) => {
  const suggestedSolutionLength = idea?.suggestedSolution?.length;
  const maxSymbols = 150;
  return (
    <Card
    elevation={0}
      style={{ minHeight: 64, cursor: "pointer", border: `1px solid ${IDEAS_STATE_COLORS?.[idea?.state]?.BG}` }}
      onClick={() => setSelectedIdea(idea)}
    >
      <div
        style={{
          height: 8,
          backgroundColor: IDEAS_STATE_COLORS?.[idea?.state]?.BG,
        }}
      />
      <CardContent>
        <Typography variant="body1">
          {suggestedSolutionLength > maxSymbols
            ? `${take(maxSymbols, idea.suggestedSolution)}...`
            : idea?.suggestedSolution}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default IdeaCard;
