import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";

const IdeaHeaderCell = ({
  title,
  icon,
  backgroundColor = "red",
  color = "white",
}) => {
  return (
    <Paper elevation={0} style={{ padding: "32px 0", backgroundColor, color }}>
      <Grid container>
        <Grid item xs={12}>
          {icon}
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" style={{ fontWeight: "bold" }}>
            {title}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default IdeaHeaderCell;
