import React from 'react';
import LoaderComponent from './loader';

export const createLoaderContainer = (Loader = LoaderComponent) => ({ fetching, children, ...props }) => {
  if (fetching) {
    return <Loader {...props} />
  }

  return children
}

export default createLoaderContainer();