import React from 'react'
import { withRouter, Link as RouterLink } from 'react-router-dom';
import { Card, CardHeader, CardContent, Grid, Button, Typography, Link } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { pathEq, path } from 'ramda';

import InputField from '../../shared/components/input';
import { withTranslate } from '../../shared/components/translate';
import { login } from 'reducers/ducks/user/token';

const getErrorMessage = (props) => {
  const message = path(["error", "message"], props);

  switch (message) {
    case 'subscription_ended':
      return "login.subscription_ended"
    case 'user_inactive':
      return "login.user_inactive"
    default:
      return "login.failed"
  }
}

const Login = withRouter(({ history, translate }) => {
  const dispatch = useDispatch();
  return (
    <Card>
      <CardHeader title={translate('login.title')} />
      <CardContent >
        <Formik
          validationSchema={Yup.object().shape({
            username: Yup.string().required().email().required(),
            password: Yup.string().required(),
          })}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            dispatch(login(values))
              .then(() => history.push('/'))
              .catch((error) => {
                const errorDescription = path(
                  ["response", "data", "error_description"],
                  error
                );
                if (errorDescription === "subscription_ended") {
                  return history.push("/home/ended-subscription");
                }

                actions.setError({
                  failed: true,
                  message: errorDescription,
                });
              }).finally(() => {
                actions.setSubmitting(false);
              });
          }}
          render={props => (
            <form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                {!!path(["error", "failed"], props) && (
                  <Grid item xs={12}>
                    <Typography color="error" variant="subtitle1">
                      {translate(getErrorMessage(props))}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} >
                  <Field
                    fullWidth
                    component={InputField}
                    label={translate('login.email')}
                    variant='outlined'
                    type='text'
                    name='username'
                  />
                </Grid>
                <Grid item xs={12} >
                  <Field
                    component={InputField}
                    fullWidth
                    name='password'
                    type='password'
                    label={translate('login.password')}
                    variant='outlined'
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    fullWidth
                    color='primary'
                    variant='contained'
                    type='submit'
                    disabled={props.isSubmitting || !props.isValid}>
                    {translate('login.login')}
                  </Button>
                </Grid >

                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Link component={RouterLink} to='/auth/forgotPassword'>
                        <Typography variant='caption' color='primary'>
                          {translate('login.remindPassword')}
                        </Typography>
                      </Link>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='caption' color='textSecondary'>
                        {translate('login.notRegister')}
                      </Typography>
                      {' '}
                      <Link component={RouterLink} to='/auth/create'>
                        <Typography variant='caption' color='primary'>
                          {translate('login.register')}
                        </Typography>
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )
          }
        />
      </CardContent>
    </Card>
  );
})

export default withTranslate(Login);