import { path } from 'ramda';
import { fetchCurrentUser } from './currentUser.service';

export const login = (credentials) => (dispatch, _, { api }) => {
  dispatch({ type: 'user/token_request' });

  return api.post('/authenticate/token', {
    ...credentials,
    "grant_type": "password",
    "client_id": "mykpi-app",
    "client_secret": "jfojlkfno1n4lk1n4lk123n4kjn2fkjrj13n42kj12n4kjn2314k",
  }, {
    headers: {
      'Content-type': 'application/json',
    }
  })
    .then((result) => {
      const token = path(['data', 'access_token'], result);
      const refreshToken = path(['data', 'refresh_token'], result);
      setRefreshToken(refreshToken)
      localStorage.setItem('token', token);
      api.defaults.headers.Authorization = `Bearer ${token}`;
      dispatch({
        type: 'user/token_success',
        payload: token
      });
      dispatch(fetchCurrentUser);
    })
}

export const setRefreshToken = token => localStorage.setItem("refresh_token", token);

export const refreshToken = (dispatch, _, { api }) => {
  const refreshToken = localStorage.getItem("refresh_token");

  return api.post('/authenticate/token', {
    "grant_type": "refresh_token",
    "client_id": "mykpi-app",
    "client_secret": "jfojlkfno1n4lk1n4lk123n4kjn2fkjrj13n42kj12n4kjn2314k",
    refresh_token: refreshToken
  })
  .then((result) => {
    const token = path(['data', 'access_token'], result);
      const refreshToken = path(['data', 'refresh_token'], result);
      setRefreshToken(refreshToken)
    localStorage.setItem('token', token);
    api.defaults.headers.Authorization = `Bearer ${token}`;
    
    dispatch({
      type: 'user/token_success',
      payload: token
    });
  })
}

export const logout = (dispatch, _, { api }) => {
  localStorage.removeItem('token');
  localStorage.removeItem('refresh_token');
  window.location.reload();
}

export const remindPassword = body => (dispatch, _, { api }) => {
  return api.post('/authenticate/remindPassword', body)
    .catch(error => {
      // todo notification error
      throw error
    })
}

export const changePasswordWithRemindKey = (key, body) => (dispatch, _, { api }) => {
  return api.post(`/authenticate/changePassword/${key}`, body)
    .catch(error => {
      // todo notification error
      throw error
    })
}

export const createPasswordWithInvitation = (key, body) => (dispatch, _, { api }) => {
  return api.post(`/authenticate/createPassword/${key}`, body)
    .catch(error => {
      // todo notification error
      throw error
    })
}