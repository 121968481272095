import React from 'react';
import { Grid } from '@material-ui/core';

import TextField from '../../shared/components/input';
import { withTranslate } from '../../shared/components/translate';
import { Field } from 'formik';

const CompanyInfo = ({ translate }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <Field
          fullWidth
          required
          label={translate("company.create.form.companyName")}
          name='company.name'
          component={TextField} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          fullWidth
          required
          type='number'
          label={translate("company.create.form.code")}
          name='company.code'
          component={TextField} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          fullWidth
          required
          autoComplete='new-email'
          label={translate("company.create.form.companyEmail")}
          name='company.email'
          type='email'
          component={TextField} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          fullWidth
          required
          type='number'
          label={translate("company.create.form.phone")}
          name='company.phone'
          component={TextField} />
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          required
          label={translate("company.create.form.description")}
          name='company.description'
          rows={3}
          component={TextField} />
      </Grid>
    </Grid>
  )
}

export default withTranslate(CompanyInfo);