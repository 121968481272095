import React from "react";

import { createField } from "../selectField";
import { Select } from "shared/components/select";
import { useTranslate } from "../translate";
import { useFetchEliminateLosses } from "./hooks";

const SelectEliminateLosses = (props) => {
  const translate = useTranslate();
  const [loading, options] = useFetchEliminateLosses();

  return (
    <Select
      {...props}
      isLoading={loading}
      options={options}
      getOptionValue={(val) => val?.id}
      getOptionLabel={(val) => translate(`enums.eliminateLosses.${val.name}`)}
      menuPlacement="bottom"
      menuPosition="fixed"
    />
  );
};

export default createField(SelectEliminateLosses);
