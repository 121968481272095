import React from "react";
import { Grid, Typography } from "@material-ui/core";
import IdeaHeaderCell from "./ideaHeaderCell";
import { IDEAS_STATE_COLORS } from "shared/enums/ideasStateColors";
import { useMatchMobile } from "shared/hooks/responsive";

const Header = () => {
  const isMobile = useMatchMobile();

  if (isMobile) {
    return null;
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={2}>
        <IdeaHeaderCell
          title="Siūloma"
          backgroundColor={IDEAS_STATE_COLORS.SUGGESTED.BG}
          color={IDEAS_STATE_COLORS.SUGGESTED.COLOR}
        ></IdeaHeaderCell>
      </Grid>
      <Grid item xs={2}>
        <IdeaHeaderCell
          title="Tikslinama"
          backgroundColor={IDEAS_STATE_COLORS.ADJUSTMENT.BG}
          color={IDEAS_STATE_COLORS.ADJUSTMENT.COLOR}
        ></IdeaHeaderCell>
      </Grid>
      <Grid item xs={2}>
        <IdeaHeaderCell
          title="Vertinama"
          backgroundColor={IDEAS_STATE_COLORS.EVALUATED.BG}
          color={IDEAS_STATE_COLORS.EVALUATED.COLOR}
        ></IdeaHeaderCell>
      </Grid>
      <Grid item xs={2}>
        <IdeaHeaderCell
          title="Įgyvendinama"
          backgroundColor={IDEAS_STATE_COLORS.IMPLEMENTING.BG}
          color={IDEAS_STATE_COLORS.IMPLEMENTING.COLOR}
        ></IdeaHeaderCell>
      </Grid>
      <Grid item xs={2}>
        <IdeaHeaderCell
          title="Įgyvendinta"
          backgroundColor={IDEAS_STATE_COLORS.IMPLEMENTED.BG}
          color={IDEAS_STATE_COLORS.IMPLEMENTED.COLOR}
        ></IdeaHeaderCell>
      </Grid>
      <Grid item xs={2}>
        <IdeaHeaderCell
          title="Atmesta"
          backgroundColor={IDEAS_STATE_COLORS.REJECTED.BG}
          color={IDEAS_STATE_COLORS.REJECTED.COLOR}
        ></IdeaHeaderCell>
      </Grid>
    </Grid>
  );
};

export default Header;
