import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    background: {
      default: "#fafafa",
    },
  },
  overrides: {
    MuiInputLabel: {
      shrink: {
        transform: 'translate(0, 1.5px)'
      },
    },
    MuiFormControlLabel: {
      label: {
        whiteSpace: "nowrap",
        fontSize: "0.875rem",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "0.875rem"
      }
    },
    MuiTypography: {
      subtitle1: {
        fontWeight: "bold",
      },
    },
    MuiPopover: {
      root: {
        zIndex: 1,
      },
    },
    MuiDialog: {
      paper: {
        right: 0,
        position: "absolute",
        height: "100vh",
        maxHeight: "100vh",
        paddingTop: 0,
        margin: 0,
        borderRadius: "4px 0 0 4px",
      },
      paperScrollPaper: {
        maxHeight: "100vh",
      },
    },
  },
});
