import React, { useEffect, useState } from "react";
import { Avatar, Button, Divider, Grid, Typography } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import moment from "moment";

import InputField from "shared/components/input";
import { useDispatch } from "react-redux";
import { createIdeaComment } from "reducers/ducks/ideaComments/createIdeaComment";
import { getIdeaComments } from "reducers/ducks/ideaComments/getIdeaComments";
import LoaderContainer from "shared/components/loaderContainer";
import CommentBox from "./commentBox";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  comment: yup.string().required(),
});
const IdeaComments = ({ idea }) => {
  const dispatch = useDispatch();
  const [ideas, setIdeas] = useState([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    if (idea.id) {
      setFetching(true);

      dispatch(getIdeaComments(idea.id))
        .then((resposne) => setIdeas(resposne.data))
        .finally(() => setFetching(false));
    }
  }, [setIdeas, dispatch, idea, setFetching]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Komentarai</Typography>
      </Grid>
      <Grid item xs={12}>
        <LoaderContainer fetching={fetching}>
          <Grid container spacing={2}>
            {ideas.map((idea) => {
              return <CommentBox key={idea.id} comment={idea} />;
            })}
          </Grid>
        </LoaderContainer>
      </Grid>
      <Grid item xs={12}>
        <Formik
          initialValues={{ comment: '' }}
          validationSchema={validationSchema}
          onSubmit={(vals, { resetForm }) => {
            dispatch(createIdeaComment(idea.id, vals))
              .then(() => resetForm())
              .then(() => setFetching(true))
              .then(() => dispatch(getIdeaComments(idea.id)))
              .then((resposne) => setIdeas(resposne.data))
              .finally(() => setFetching(false));
          }}
        >
          <Form noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  required
                  fullWidth
                  name="comment"
                  label="Komentaras"
                  component={InputField}
                  InputProps={{ multiline: true, rows: 4 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Komentuoti
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </Grid>
    </Grid>
  );
};

export default IdeaComments;
