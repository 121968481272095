import { combineReducers } from "redux";
import { localizeReducer } from "react-localize-redux";

import {
  reducer as notificationReducer,
  NAME,
} from "../shared/components/notifications";
import departmentReducer from "./ducks/department";
import userReducer from "./ducks/user";
import ideasReducer from "./ducks/idea";
import commissionReducer from "./ducks/commission";

export default combineReducers({
  user: userReducer,
  localize: localizeReducer,
  department: departmentReducer,
  commission: commissionReducer,
  ideas: ideasReducer,
  [NAME]: notificationReducer,
});
