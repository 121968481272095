import Ideas from "components/ideas/view";
import React from "react";
import { Route, Switch } from "react-router-dom";

import MainLayout from "../shared/layouts/main-layout";
import ViewIdeas from "components/ideas/view";
import UserRoutes from "./users.routes";
import DepartmentRoute from "./department.routes";
import CommissionRoute from "./commission";
import roleGroupRoutes from "./roleGroup.routes";
// import Profile from "../components/users/profile";

export default (props) => (
  <MainLayout>
    <Switch>
      <Route path="/department" component={DepartmentRoute} />
      <Route path="/commission" component={CommissionRoute} />
      <Route path="/users" component={UserRoutes} />
      <Route path="/roleGroup" component={roleGroupRoutes} />
      <Route path="/" component={ViewIdeas} />
      {/* <Route path="/profile" component={Profile} /> */}
    </Switch>
  </MainLayout>
);
