import React, { useContext } from "react";
import { FormControlLabel, Checkbox, Radio } from "@material-ui/core";

import { withTranslate } from "../translate";
import { CheckboxGroupContext } from "./context";

const GroupCheckbox = ({ label, ...props }) => {
  const {
    value,
    onChange,
    valueField,
    labelField,
    variant,
  } = useContext(CheckboxGroupContext);

  const getValue = (val) => (!!valueField ? valueField(val) : val);
  const checkboxValue = getValue(props.value);

  const isSelected = () => {
    if (variant === "radio") {
      return String(getValue(value)).toString() == String(checkboxValue).toString();
    }

    return (
      (value || []).findIndex((val) => getValue(val) == checkboxValue) >= 0
    );
  };

  const innerOnChange = (e, checked) => {
    if (variant === "radio") {
      return undefined;
    }
    const index = (value || []).findIndex(
      (val) => getValue(val) == checkboxValue
    );

    const val = [...(value || [])];
    if (checked) {
      val.push(props.value);

      onChange(val);
    } else {
      val.splice(index, 1);
      onChange(val);
    }
  };

  return (
    <FormControlLabel
      label={label}
      onChange={innerOnChange}
      value={props.value}
      readOnly
      control={
        variant === "radio" ? (
          <Radio color="primary" checked={isSelected()} />
        ) : (
            <Checkbox checked={isSelected()} color="primary" />
          )
      }
    />
  );
};

export default withTranslate(GroupCheckbox);
