import { createRequestMessageHandler } from "shared/utils/httpRequestMessageHandler";
import { createSetData, createSetFetching } from "../common/fetch";

export const setCurrentUser = createSetData("current-user");
export const setFetchingCurrentUser = createSetFetching("current-user");

export const fetchCurrentUser = () => (dispatch, getState, { api }) => {
  dispatch(setFetchingCurrentUser(true));

  return api
    .get("/private/user/current")
    .then((response) => {
      dispatch(setCurrentUser(response.data));

      return response;
    })
    .catch((error) => dispatch(createRequestMessageHandler()(error)))
    .finally(() => dispatch(setFetchingCurrentUser(false)));
};
