import React from 'react';
import { useDispatch } from 'react-redux';
import { path } from 'ramda';

import { AsyncSelect } from 'shared/components/select';
import { createField } from './selectField';
import { useTranslate } from './translate';

const fetchUserList = (q = '') => (_, __, { api }) => {
  return api.get('/private/user/list')
    .then(response => response.data)
    .catch(() => []);
}

const SelectUser = (props) => {
  const dispatch = useDispatch();
  const translate = useTranslate();

  return (
    <AsyncSelect
      {...props}
      cacheOptions
      defaultOptions
      getOptionValue={path(["id"])}
      noOptionsMessage={() => translate("common.noRecords")}
      getOptionLabel={val => `${path(['firstName'], val)} ${path(['lastName'], val)}`}
      menuPlacement='bottom'
      menuPosition='fixed'
      loadOptions={val => dispatch(fetchUserList(val))}
    />
  );
}

export default createField(SelectUser)