import { pathOr, path } from "ramda";
import { ROLES } from "../common/roles";
import { createSelector } from "reselect";

export const getUserList = pathOr([], ["user", "list", "data"]);
export const getUserComposedRoles = pathOr(
  [],
  ["user", "current", "data", "combinedRoles"]
);
export const getUserId = path(["user", "current", "data", "id"]);
export const getCurrentUser = pathOr({}, ["user", "current", "data"]);
export const getUserDepartments = pathOr("", [
  "user",
  "current",
  "department",
  "data",
]);
export const getUserManagedDepartments = pathOr("", [
  "user",
  "current",
  "departmentManager",
]);

export const isUserCommissionMember = pathOr(false, [
  "user",
  "current",
  "data",
  "commissionMember",
]);
export const isUserCommissionPresident = pathOr(false, [
  "user",
  "current",
  "data",
  "commissionPresident",
]);

export const isUserInCommission = createSelector(
  isUserCommissionMember,
  isUserCommissionPresident,
  (isMember, isPresident) => {
    return !!isMember || !!isPresident;
  }
);

export const getUserComposedDepartments = createSelector(
  getUserDepartments,
  getUserManagedDepartments,
  (userDepartment, managedDepartments) => {
    return [...managedDepartments, userDepartment];
  }
);
