import React from "react";
import { useSelector } from "react-redux";
import {
  isUserCommissionMember,
  isUserCommissionPresident,
} from "selectors/user.selector";

import CommissionMember from "./commissionMember";
import CommissionPresident from "./commissionPresident";
import CommissionEvaluations from "./commissionEvaluations";
import { IDEAS_STATE } from "common/ideasState";

const Commission = (props) => {
  const isMember = useSelector(isUserCommissionMember);
  const isPresident = useSelector(isUserCommissionPresident);

  if (
    [
      IDEAS_STATE.REJECTED,
      IDEAS_STATE.ADJUSTMENT,
      IDEAS_STATE.IMPLEMENTED,
      IDEAS_STATE.IMPLEMENTING
    ].indexOf(props.idea.state) >= 0
  ) {
    return <CommissionEvaluations {...props} />
  }

  if (isMember) {
    return <CommissionMember {...props} />;
  }

  if (isPresident) {
    return <CommissionPresident {...props} />;
  }

  return null;
};

export default Commission;
