import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";

const AuthLayout = ({ children }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <Grid
      container
      justify="center"
      style={{ backgroundColor: "#F4F7F8", minHeight: "100vh" }}
    >
      <Grid item xs={12} style={{ padding: "92px 0 240px 0" }}>
        <Grid container justify="center" alignItems="center" className="h-100">
          <Grid item xs={9} sm={6} lg={5}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
