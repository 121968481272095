import {
  EmojiObjects,
  Person,
  People,
  SettingsApplications,
  AccessibilityNew,
} from "@material-ui/icons";
import { ROLES } from "../../../common/roles";

export default [
  {
    title: "navigation.ideas",
    icon: EmojiObjects,
    pagePath: "/",
    exact: true,
    canSeeAll: true
  },
  {
    title: "navigation.administration",
    icon: SettingsApplications,
    allowedRoles: [ROLES["DEPARTMENT-READ"], ROLES["USER-READ"], ROLES["ROLE-GROUPS-READ"], ROLES["COMMISSION-GET"]],
    childs: [
      {
        title: "navigation.department",
        icon: People,
        pagePath: "/department",
        allowedRoles: [ROLES["DEPARTMENT-READ"]],
      },
      {
        title: "navigation.users",
        icon: Person,
        pagePath: "/users",
        allowedRoles: [ROLES["USER-READ"]],
      },
      {
        title: "navigation.commission",
        icon: AccessibilityNew,
        pagePath: "/commission",
        allowedRoles: [ROLES["COMMISSION-GET"]],
      },
      {
        title: 'navigation.roleGroup',
        icon: AccessibilityNew,
        pagePath: '/roleGroup',
        allowedRoles: [ROLES["ROLE-GROUPS-READ"]]
      },
    ],
  },
];
