import React, { useEffect, useState } from "react";
import { Button, Collapse, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { groupBy, prop } from "ramda";
import Header from "./ideasHeader";
import GroupGrid from "./groupGrid";
import AddIdea from "../addIdea";
import { listIdeas } from "reducers/ducks/idea/listIdeas";
import {
  getGroupedIdeasByDepartment,
  getIdeasFilter,
  getListedIdeas,
} from "selectors/idea.selector";
import EditIdea from "../editIdea";
import PageHeader from "shared/components/pageHeader";
import IdeaFilter from "./filter";

const Ideas = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedIdea, setSelectedIdea] = useState();
  const [openFilter, setOpenFilter] = useState(false);
  const ideas = useSelector(getGroupedIdeasByDepartment);
  const filter = useSelector(getIdeasFilter);

  useEffect(() => {
    dispatch(listIdeas());
  }, [dispatch, filter]);

  return (
    <>
      <AddIdea open={open} setOpen={setOpen} />
      <EditIdea
        open={!!selectedIdea}
        selectedIdea={selectedIdea}
        setOpen={() => setSelectedIdea(undefined)}
      />
      <Grid container>
        <Grid item xs={12}>
          <PageHeader title="Idėjos">
            <Grid container justify="flex-end" spacing={1}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpenFilter(open => !open)}
                >
                  Filtras
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setOpen(true)}
                >
                  Pridėti idėją
                </Button>
              </Grid>
            </Grid>
          </PageHeader>
        </Grid>
        <Grid item xs={12}>
          <div style={{ padding: 16 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Collapse in={openFilter}>
                  <IdeaFilter />
                </Collapse>
              </Grid>
              <Grid item xs={12}>
                <Header />
              </Grid>
              {Object.keys(ideas).map((key) => {
                return (
                  <Grid item xs={12} key={key}>
                    <GroupGrid
                      setSelectedIdea={setSelectedIdea}
                      ideaGroup={ideas[key]}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Ideas;
