export const ROLES = {
  "DEPARTMENT-CREATE": "DEPARTMENT-CREATE",
  "DEPARTMENT-UPDATE": "DEPARTMENT-UPDATE",
  "DEPARTMENT-READ": "DEPARTMENT-READ",
  "DEPARTMENT-DELETE": "DEPARTMENT-DELETE",
  "USER-CREATE": "USER-CREATE",
  "USER-UPDATE": "USER-UPDATE",
  "USER-READ": "USER-READ",
  "ROLE-GROUPS-CREATE": "ROLE-GROUPS-CREATE",
  "ROLE-GROUPS-UPDATE": "ROLE-GROUPS-UPDATE",
  "ROLE-GROUPS-READ": "ROLE-GROUPS-READ",
  "ROLE-GROUPS-DELETE": "ROLE-GROUPS-DELETE",
  "COMMISSION-GET": "COMMISSION-GET",
  "COMMISSION-EDIT": "COMMISSION-EDIT",
};
