import React from "react";

import { useDispatch } from "react-redux";

const fetchEliminateLosses = (q = "") => (_, __, { api }) => {
  return api
    .get("/private/ideaEffects/eliminate-losses")
    .then((response) => response.data)
    .catch(() => []);
};

export const useFetchEliminateLosses = () => {
  const dispatch = useDispatch();
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    dispatch(fetchEliminateLosses())
      .then((data) => setOptions(data))
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, setOptions]);

  return [loading, options];
};
