import { combineReducers } from "redux";

import fetchReducer from "../common/fetch";
import createNamedWrapperReducer from "../common/utils";
export const reinviteUser = (id) => (dispatch, getState, { api }) => {
  return api.get(`/private/user/reinvite/${id}`);
};
export default combineReducers({
  token: createNamedWrapperReducer(fetchReducer, "token"),
  current: createNamedWrapperReducer(fetchReducer, "current-user"),
  list: createNamedWrapperReducer(fetchReducer, "list-user"),
});
