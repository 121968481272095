import { assocPath, path } from "ramda";
import { compose } from "redux";

const setIfExist = (valuePath, formatter, filterPath) => (originalFilter) => (
  value
) => {
  const val = path(valuePath, originalFilter);

  if (Array.isArray(val)) {
    return assocPath(filterPath || valuePath, val.map(formatter), value);
  }

  if (val) {
    return assocPath(filterPath || valuePath, formatter(val), value);
  }

  return value;
};

export const ideaConverter = {
  forFE(idea) {
    return idea;
  },
  forBE(idea) {
    return idea;
  },
  filterQuery(filter) {
    return compose(
      setIfExist(["state"], (val) => val.value)(filter),
      setIfExist(["eliminateLosses"], (val) => val.id)(filter),
      setIfExist(["improveAreas"], (val) => val.id)(filter),
      setIfExist(["department"], (val) => val.id)(filter),
      setIfExist(["createdAt"], (val) => val)(filter),
      setIfExist(["createdBy"], (val) => val.id)(filter),
      setIfExist(["createdUntil"], (val) => val)(filter)
    )({});
  },
};
