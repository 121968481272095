import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";

import IdeaForm from "../shared/ideaForm";
import { createIdeaApi } from "reducers/ducks/idea/createIdea";
import { listIdeas } from "reducers/ducks/idea/listIdeas";
import { ideaValidationSchema } from "../shared/ideaForm/validation";
import DialogTitle from "shared/components/dialog/dialogTitle";

const AddIdea = ({ open, setOpen }) => {
  const dispatch = useDispatch();

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle onClose={() => setOpen(false)}>Pridėti idėją</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{ implementYourself: "false", problem: '', currentSituation: '', suggestedSolution: '' }}
          validationSchema={ideaValidationSchema}
          onSubmit={(vals, { setSubmitting, setErrors, setTouched }) => {
            setSubmitting(true);
            dispatch(createIdeaApi(vals))
              .catch((error) => {
                if (error?.response?.data?.errors) {
                  setErrors(error?.response?.data?.errors);
                }
                throw error;
              })
              .then(() => dispatch(listIdeas()))
              .then(() => setOpen(false))
              .finally(() => setSubmitting(false));
          }}
        >
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <IdeaForm />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      onClick={() => setOpen(false)}
                    >
                      Atšaukti
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      color="primary"
                      variant="contained"
                      type="submit"
                    >
                      Pridėti
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddIdea;
