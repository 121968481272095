export const changeIdeaState = (id, state) => (dispatch, getState, { api }) => {
  return api.put(`/private/idea/idea/${id}/state/${state}`);
};

export const commissionPresidentEvaluation = (id, state, values) => (
  dispatch,
  getState,
  { api }
) => {
  return api.put(`/private/idea/idea/${id}/state/${state}/president`, values);
};
