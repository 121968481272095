import React from 'react';
import { Field } from 'formik';
import { Grid } from '@material-ui/core';

import TextField from 'shared/components/input';
import SelectRole from 'shared/components/select-role';
import { useTranslate } from 'shared/components/translate';

const RoleGroupForm = () => {
  const translate = useTranslate();

  return (
    <Grid container>
      <Grid item xs={12} >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Field
              required
              fullWidth
              name='name'
              label={translate("roleGroup.table.name")}
              component={TextField}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              required
              fullWidth
              name='description'
              label={translate("roleGroup.table.description")}
              component={TextField}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              required
              isMulti
              fullWidth
              name='roles'
              label={translate("roleGroup.table.roles")}
              component={SelectRole}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default RoleGroupForm;