import { createRequestMessageHandler } from "shared/utils/httpRequestMessageHandler";
import { createSetData, createSetFetching } from "../common/fetch";

export const setListUser = createSetData("list-user");
export const setFetchingListUser = createSetFetching("list-user");

export const listUsers = () => (dispatch, getState, { api }) => {
  dispatch(setFetchingListUser(true));

  return api
    .get("/private/user/list")
    .then((response) => {
      dispatch(setListUser(response.data));

      return response;
    })
    .catch((error) => dispatch(createRequestMessageHandler()(error)))
    .finally(() => dispatch(setFetchingListUser(false)));
};
