import React, { useState } from 'react';

import Dialog from './dialog';
import DialogInput from './input';

const DialogSelect = ({ options, disabled, ...props }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {open && <Dialog open={open} setOpen={setOpen} options={options} {...props} />}
      <DialogInput setOpen={setOpen} options={options} disabled={disabled} {...props} />
    </>
  );
}

export default DialogSelect