import qs from 'qs';
import { getIdeasFilter } from 'selectors/idea.selector';

import { createRequestMessageHandler } from "shared/utils/httpRequestMessageHandler";
import { createSetData, createSetFetching } from "../common/fetch";
import { ideaConverter } from './converter';

export const setListedIdeas = createSetData("list-ideas");
export const setFetchingListedIdeas = createSetFetching("list-ideas");

export const listIdeas = () => (dispatch, getState, { api }) => {
  dispatch(setFetchingListedIdeas(true));
  const filter = getIdeasFilter(getState());

  return api
    .get(`/private/idea/list?${qs.stringify(ideaConverter.filterQuery(filter))}`)
    .then((response) => {
      dispatch(setListedIdeas(response.data));

      return response;
    })
    .catch((error) => dispatch(createRequestMessageHandler()(error)))
    .finally(() => dispatch(setFetchingListedIdeas(false)));
};
