import React, { useEffect, useMemo, useState } from "react";
import { Avatar, Grid, Tooltip, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { getIdeaEvaluations } from "reducers/ducks/ideaEvaluation/getIdeaEvaluations";
import LoaderContainer from "shared/components/loaderContainer";
import {
  EVALUATIION_STATE_COLORS,
  Evaluation,
} from "shared/enums/evaluationType";

const getColor = (evaluation) => {
  switch (evaluation) {
    case Evaluation.ACCEPT:
      return EVALUATIION_STATE_COLORS.ACCEPT;
    case Evaluation.REJECT:
      return EVALUATIION_STATE_COLORS.REJECT;
    case Evaluation.ADJUST:
      return EVALUATIION_STATE_COLORS.ADJUST;
    default:
      return "grey";
  }
};

const TooltipTitle = ({ evaluation }) => {
  if (!evaluation) {
    return "Nėra vertinimo";
  }

  return (
    <div>
      {`${evaluation?.createdBy?.firstName}  ${evaluation?.createdBy?.lastName} `}
      <br />
      {!!evaluation.comment && (
        <div>
          <b>Komentaras:</b> {evaluation.comment}
        </div>
      )}
    </div>
  );
};

const CommissionEvaluations = ({ idea }) => {
  const [evaluations, setEvaluations] = useState({});
  const [fetching, setFetching] = useState(false);
  const dispatch = useDispatch();
  const id = idea.id;
  const totalMembers = evaluations?.totalMembers +1 || 0;

  const emptyArray = useMemo(() => Array(totalMembers).fill(0), [totalMembers]);
  const arr = emptyArray.length >= evaluations?.evaluations?.length ? emptyArray : evaluations?.evaluations
  useEffect(() => {
    if (!!id) {
      setFetching(true);
      dispatch(getIdeaEvaluations(id))
        .then((response) => setEvaluations(response.data))
        .finally(() => setFetching(false));
    }
  }, [dispatch, id]);

  return (
    <LoaderContainer fetching={fetching}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Komisijos vertinimas</Typography>
        </Grid>
        {arr?.map((key, index) => {
          const evaluation = evaluations?.evaluations?.[index];

          return (
            <Grid item key={evaluation?.id || index}>
              <Tooltip title={<TooltipTitle evaluation={evaluation} />}>
                <Avatar
                  variant="circle"
                  style={{
                    background: getColor(evaluation?.evaluation),
                  }}
                />
              </Tooltip>
            </Grid>
          );
        })}
      </Grid>
    </LoaderContainer>
  );
};

export default CommissionEvaluations;
