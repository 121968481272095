const { createSetData, createSetFetching } = require("../common/fetch");

const setCommissionMembers = createSetData("commission-members");
const setFetchingCommissionMembers = createSetFetching("commission-members");

export const fetchCommissionMembers = (dispatch, getState, { api }) => {
  dispatch(setFetchingCommissionMembers(true));

  return api
    .get("/private/commission/get")
    .then((response) => dispatch(setCommissionMembers(response.data)))
    .finally(() => dispatch(setFetchingCommissionMembers(false)));
};
