import React, { useState } from "react";
import { Formik, Field } from "formik";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Link,
} from "@material-ui/core";
import { path, compose } from "ramda";
import { useDispatch } from "react-redux";
import { Link as RouterLink, withRouter } from "react-router-dom";

import PageHeader from "../../../shared/components/pageHeader";
import { withTranslate } from "../../../shared/components/translate";
import { actions } from "../../../shared/components/notifications";
import { fetchUserById, editUserById } from "../../../services/users.service";

import BasicInfo from "../form/basicInfo";
import RightsInfo from "../form/rights";
import { baseValidationSchema } from "../form/validation";
import { reinviteUser } from 'reducers/ducks/user';

const Edit = (props) => {
  const dispatch = useDispatch();
  const [user, setUser] = React.useState();
  const id = path(["match", "params", "id"], props);
  const [inviting, setInviting] = useState(false);

  React.useEffect(() => {
    dispatch(fetchUserById(id)).then(setUser);
  }, []);

  return (
    <Grid container>
      <Grid item container>
        <PageHeader title={props.translate("users.edit.title")} />
      </Grid>
      <Grid item xs={12} md={5}>
        <div style={{ padding: 16 }}>
          <Formik
            enableReinitialize
            initialValues={user}
            validationSchema={baseValidationSchema}
            onSubmit={(val, { setSubmitting, setErrors }) => {
              setSubmitting(true);
              dispatch(editUserById(id, val))
                .then(() => props.history.push("/users"))
                .then(() =>
                  dispatch(
                    actions.displayMessage(
                      "success",
                      props.translate("notifications.success.edit")
                    )
                  )
                )
                .catch((err) => {
                  const errors = path(["response", "data", "errors"], err);
                  dispatch(
                    actions.displayMessage(
                      "error",
                      props.translate("notifications.errors.edit")
                    )
                  );
                  if (errors) setErrors(errors);
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
            render={(formProps) => {
              return (
                <form onSubmit={formProps.handleSubmit}>
                  <Card>
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <BasicInfo />
                        </Grid>
                        <Grid item xs={12}>
                          <RightsInfo />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions>
                      <Link component={RouterLink} to="/users">
                        <Button>{props.translate("common.cancel")}</Button>
                      </Link>
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={formProps.isSubmitting}
                      >
                        {props.translate("common.edit")}
                      </Button>
                      <Field name="emailConfirmed">
                        {(fieldProps) => {
                          if (fieldProps.field.value) {
                            return null;
                          }

                          return (
                            <Button
                              variant="contained"
                              color="secondary"
                              disabled={inviting}
                              onClick={() => {
                                setInviting(true)
                                dispatch(reinviteUser(id))
                                  .then(() => {
                                    setInviting(false)

                                    return dispatch(actions.displayMessage('success', props.translate("notifications.success.email")))
                                  })
                                  .catch(() => dispatch(actions.displayMessage('error', props.translate("notifications.errors.email"))))
                              }}
                            >
                              {props.translate("users.form.rights.reinvite")}
                            </Button>
                          );
                        }}
                      </Field>
                    </CardActions>
                  </Card>
                </form>
              );
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default compose(withTranslate, withRouter)(Edit);
