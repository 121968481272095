import React from "react";
import { useMatchMobile } from "shared/hooks/responsive";
import Desktop from "./desktop";
import Mobile from "./mobile";

const ContentSwitch = (props) => {
  const isMobile = useMatchMobile();
  if (isMobile) {
    return <Mobile {...props} />;
  }

  return <Desktop {...props} />
};

export default ContentSwitch;
