import { createSetData } from "../common/fetch";

export const setToken = createSetData("token");

export const login = (values) => (dispatch, getState, { api }) => {
  return api
    .post("/authenticate/token", {
      client_id: "mykaizen-app",
      client_secret: "3052d180-282f-11eb-aca1-714a59592cc4",
      grant_type: "password",
      ...values,
    })
    .then((response) => {
      api.defaults.headers.Authorization = `Bearer ${response.data?.access_token}`;
      localStorage.setItem("access_token", response.data?.access_token);
      localStorage.setItem("refresh_token", response.data?.refresh_token);
      dispatch(setToken(response.data?.access_token));

      return response;
    })
};
