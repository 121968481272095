import React from "react";
import { contains, compose } from "ramda";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withRouter } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Collapse } from "@material-ui/core";

import { withTranslate } from "../../components/translate";
import { AuthorizeComponent } from "../../components/authorizeRoute";
import { WithRbac } from "common/rbac";

const useStyles = makeStyles((theme) => ({
  backgroundColor: {
    backgroundColor: theme.palette.grey[200],
  },
}));

const MenuButton = compose(
  withTranslate,
  withRouter
)(({ title, icon: Icon, pagePath, exact, handleDrawerClose, ...props }) => {
  const { pathname } = props.location;
  const selected = exact ? pagePath === pathname : contains(pagePath, pathname);
  return (
    <ListItem
      button
      key={title}
      selected={selected}
      onClick={() => {
        handleDrawerClose();
        props.history.push(pagePath);
      }}
    >
      <ListItemIcon>
        <Icon color={selected ? "secondary" : "inherit"} />
      </ListItemIcon>
      <ListItemText primary={props.translate(title)} />
    </ListItem>
  );
});

const MenuButtonWithChild = withTranslate(
  ({ title, icon: Icon, childs, handleDrawerClose, ...props }) => {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    return (
      <React.Fragment>
        <ListItem button onClick={() => setOpen(!open)}>
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          <ListItemText primary={props.translate(title)} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <div className={classes.backgroundColor}>
            <MenuList
              menuItems={childs}
              handleDrawerClose={handleDrawerClose}
            />
          </div>
        </Collapse>
      </React.Fragment>
    );
  }
);

const MenuButtonSwitcher = (props) => {
  if (props.childs) {
    return <MenuButtonWithChild {...props} />;
  }
  return <MenuButton {...props} />;
};

export const MenuList = ({ menuItems, handleDrawerClose }) => {
  return (
    <List disablePadding>
      {menuItems.map(({ allowedRoles, canSeeAll, ...props }, index) => {
        if (canSeeAll) {
          return (
            <MenuButtonSwitcher
              handleDrawerClose={handleDrawerClose}
              {...props}
            />
          );
        }
        return (
          <WithRbac key={props.title} action={allowedRoles}>
            <MenuButtonSwitcher
              handleDrawerClose={handleDrawerClose}
              {...props}
            />
          </WithRbac>
        );
      })}
    </List>
  );
};
