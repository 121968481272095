import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import selectEliminateLosses from "shared/components/selectEliminateLosses/selectEliminateLosses";
import selectImproveAreas from "shared/components/selectImproveAreas";
import selectDepartment from "shared/components/select-department";
import SelectIdeaStates from "shared/components/selectIdeaState";
import { IDEAS_STATE } from "common/ideasState";
import { useDispatch, useSelector } from "react-redux";
import { setIdeaFilter } from "reducers/ducks/idea/filterIdea";
import { getIdeasFilter } from "selectors/idea.selector";
import { KeyboardDatePickerField } from "shared/components/datePicker";
import selectUser from "shared/components/select-user";

const IdeaFilter = () => {
  const dispatch = useDispatch();
  const initialValues = useSelector(getIdeasFilter);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(vals) => {
        dispatch(setIdeaFilter(vals));
      }}
    >
      <Form>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Filtras</Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Field
              isMulti
              name="state"
              component={SelectIdeaStates}
              label="Būsena"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Field
              isMulti
              name="eliminateLosses"
              component={selectEliminateLosses}
              rowSpan={4}
              label="Šalina nuostolius"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Field
              isMulti
              name="improveAreas"
              component={selectImproveAreas}
              rowSpan={4}
              label="Pagerina"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Field
              isMulti
              name="department"
              component={selectDepartment}
              rowSpan={4}
              label="Grupė"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Field
              isMulti
              name="createdBy"
              component={selectUser}
              label="Idėją pateikęs"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Field
              name="createdAt"
              disableToolbar
              component={KeyboardDatePickerField}
              variant="inline"
              label="Sukurta nuo"
              fullWidth
              format="YYYY-MM-DD"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Field
              name="createdUntil"
              variant="inline"
              disableToolbar
              component={KeyboardDatePickerField}
              label="Sukurta iki"
              fullWidth
              format="YYYY-MM-DD"
            />
          </Grid>

          <Grid item xs={12}>
            <Button color="secondary" variant="contained" type="submit">
              Ieškoti
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};

export default IdeaFilter;
