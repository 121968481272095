import { Grid } from "@material-ui/core";
import React from "react";

import IdeaCard from "../../ideaCard";

const GroupedIdeasMapper = ({ ideas, state, setSelectedIdea }) => {
  if (!ideas?.[state]) {
    return null;
  }

  return (
    <Grid container spacing={1} wrap="nowrap">
      {/* <Grid item xs={1}>
        
      </Grid> */}
      <Grid item xs={12}>
        <Grid container spacing={1}>
          {ideas?.[state]?.map((idea) => (
            <Grid item xs={11}>
              <IdeaCard setSelectedIdea={setSelectedIdea} key={idea.id} {...idea} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GroupedIdeasMapper;
