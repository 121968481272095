import React, { useState } from "react";
import { Collapse, Divider, Grid, IconButton, Typography } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons";

import IdeaCard from "../ideaCard";
import { groupBy, prop } from "ramda";
import { IDEAS_STATE } from "common/ideasState";
import GroupedIdeasMapper from "./groupedIdeasMapper";

const GroupGrid = ({ ideaGroup, setSelectedIdea }) => {
  const [open, setOpen] = useState(true);
  const groupedIdeasByState = groupBy(prop("state"), ideaGroup);
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid
          item
          xs={12}
          container
          spacing={1}
          alignItems="center"
          onClick={() => setOpen((open) => !open)}
          style={{ cursor: "pointer" }}
        >
          <Grid item>
            <IconButton>
              {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>
          </Grid>
          <Grid item>
            <Typography>
              {ideaGroup?.[0]?.department?.name || "Nepriskirta"}
            </Typography>
          </Grid>
          <Grid item xs>
            <Divider variant="fullWidth" />
          </Grid>
        </Grid>
        <Grid item>
          <Collapse in={open}>
            <Grid container spacing={1} style={{ padding: 8 }}>
              <Grid item xs={2}>
                <GroupedIdeasMapper
                  setSelectedIdea={setSelectedIdea}
                  ideas={groupedIdeasByState}
                  state={IDEAS_STATE.SUGGESTED}
                />
              </Grid>
              <Grid item xs={2}>
                <GroupedIdeasMapper
                  setSelectedIdea={setSelectedIdea}
                  ideas={groupedIdeasByState}
                  state={IDEAS_STATE.ADJUSTMENT}
                />
              </Grid>
              <Grid item xs={2}>
                <GroupedIdeasMapper
                  setSelectedIdea={setSelectedIdea}
                  ideas={groupedIdeasByState}
                  state={IDEAS_STATE.EVALUATED}
                />
              </Grid>
              <Grid item xs={2}>
                <GroupedIdeasMapper
                  setSelectedIdea={setSelectedIdea}
                  ideas={groupedIdeasByState}
                  state={IDEAS_STATE.IMPLEMENTING}
                />
              </Grid>
              <Grid item xs={2}>
                <GroupedIdeasMapper
                  setSelectedIdea={setSelectedIdea}
                  ideas={groupedIdeasByState}
                  state={IDEAS_STATE.IMPLEMENTED}
                />
              </Grid>
              <Grid item xs={2}>
                <GroupedIdeasMapper
                  setSelectedIdea={setSelectedIdea}
                  ideas={groupedIdeasByState}
                  state={IDEAS_STATE.REJECTED}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GroupGrid;
