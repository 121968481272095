import React, { useState, useEffect } from 'react';
import RSSelect from 'react-select';
import RSSAsyncSelect from 'react-select/async';
import { useTranslate } from '../translate';
import { useMatchMobile } from 'shared/hooks/responsive';
import { Input, Dialog, Grid, ListItem, ListItemText, List, IconButton, makeStyles, Typography, TextField } from '@material-ui/core';
import { Menu, Close } from '@material-ui/icons';
import DialogSelect from './dialogSelect';

const customStyles = {
  control: () => ({
    display: "flex",
    alignItems: "center",
    border: 0,
    height: "auto",
    background: "transparent",
    "&:hover": {
      boxShadow: "none"
    }
  }),
  menu: () => ({
    backgroundColor: "white",
    boxShadow: "1px 2px 6px #888888", // should be changed as material-ui
    position: "absolute",
    left: 0,
    top: `calc(100% + 1px)`,
    width: "100%",
    zIndex: 2,
    // maxHeight: ITEM_HEIGHT * 4.5
  }),
  menuList: () => ({
    // maxHeight: ITEM_HEIGHT * 4.5,
    overflowY: "auto"
  })
};

export const Select = ({ styles, ...props }) => {
  const translate = useTranslate();
  const mobile = useMatchMobile();

  if (mobile) {
    return (
      <DialogSelect {...props} />
    );
  }

  return (
    <RSSelect
      {...props}
      styles={styles}
      isDisabled={props.disabled}
      noOptionsMessage={() => translate("common.noRecords")}
    />)
}

export default Select;