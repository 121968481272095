import React from 'react'
import { Link, withRouter } from 'react-router-dom';
import { Card, CardHeader, CardContent, CardActions, Button } from '@material-ui/core';

import { withTranslate } from '../../shared/components/translate';

const ForgotPasswordSuccess = withRouter(({ translate }) => {
  return (
    <Card>
      <CardHeader title={translate('auth.forgotPasswordSuccess.title')} />
      <CardContent>
        {translate('auth.forgotPasswordSuccess.description')}
      </CardContent>
      <CardActions>
        <Button component={Link} to="/auth/login" variant="contained" color="secondary">{translate("login.login")}</Button>
      </CardActions>
    </Card>
  );
})

export default withTranslate(ForgotPasswordSuccess);