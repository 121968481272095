import React, { useEffect, useState } from "react";
import { Avatar, Button, Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import { Field, Form, Formik } from "formik";
import selectUser from "shared/components/select-user";
import {
  changeIdeaState,
  commissionPresidentEvaluation,
} from "reducers/ducks/idea/changeIdeaState";
import { IDEAS_STATE } from "common/ideasState";
import { listIdeas } from "reducers/ducks/idea/listIdeas";
import { confirmIdeaApi } from "reducers/ducks/idea/confirmIdea";
import CommissionEvaluations from "./commissionEvaluations";
import { IDEAS_STATE_COLORS } from "shared/enums/ideasStateColors";
import InputField from "shared/components/input";
import { Evaluation } from "shared/enums/evaluationType";
import LoaderContainer from "shared/components/loaderContainer";

const validationSchema = yup.object().shape({
  assignee: yup.object().required(),
  comment: yup.string().required(),
});

const CommissionVerdict = ({ idea, closeModal }) => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);

  return (
    <Formik initialValues={{}}>
      {({ values, setFieldTouched, isValid, setErrors }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CommissionEvaluations idea={idea} />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Field
                    name="assignee"
                    label="Atsakingas už idėjos įgyvendinimą asmuo"
                    component={selectUser}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="comment"
                    component={InputField}
                    fullWidth
                    label="Vertintojo komentaras"
                    InputProps={{ multiline: true, rows: 4 }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Button
                    disabled={fetching}
                    fullWidth
                    variant="contained"
                    color="secondary"
                    style={{
                      backgroundColor: IDEAS_STATE_COLORS.REJECTED.BG,
                      color: IDEAS_STATE_COLORS.REJECTED.COLOR,
                    }}
                    onClick={() => {
                      if (!values.comment) {
                        setErrors({ comment: "validation.mixed.required" });
                        return setFieldTouched("comment", true, false);
                      }
                      setFetching(true);
                      dispatch(
                        commissionPresidentEvaluation(
                          idea.id,
                          Evaluation.REJECT,
                          values
                        )
                      )
                        .then(() => dispatch(listIdeas()))
                        .then(() => closeModal())
                        .finally(() => setFetching(false));
                    }}
                  >

                    <LoaderContainer fetching={fetching} size="1rem">
                      Atmesti
                    </LoaderContainer>
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    disabled={fetching}
                    style={{
                      backgroundColor: IDEAS_STATE_COLORS.ADJUSTMENT.BG,
                      color: IDEAS_STATE_COLORS.ADJUSTMENT.COLOR,
                    }}
                    onClick={() => {
                      if (!values.comment) {
                        setErrors({ comment: "validation.mixed.required" });
                        return setFieldTouched("comment", true, false);
                      }

                      setFetching(true)
                      dispatch(
                        commissionPresidentEvaluation(
                          idea.id,
                          Evaluation.ADJUST,
                          values
                        )
                      )
                        .then(() => dispatch(listIdeas()))
                        .then(() => closeModal())
                        .finally(() => setFetching(false));
                    }}
                  >
                    <LoaderContainer fetching={fetching} size="1rem">
                      Patikslinti
                    </LoaderContainer>
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    disabled={fetching}
                    variant="contained"
                    onClick={() => {
                      if (!values.assignee) {
                        setErrors({ assignee: "validation.mixed.required" });
                        return setFieldTouched("assignee", true, false);
                      }
                      setFetching(true)
                      dispatch(
                        commissionPresidentEvaluation(
                          idea.id,
                          Evaluation.ACCEPT,
                          values
                        )
                      )
                        .then(() => dispatch(listIdeas()))
                        .then(() => closeModal())
                        .then(() => setFetching(false));
                    }}
                    color="primary"
                    style={{
                      backgroundColor: IDEAS_STATE_COLORS.IMPLEMENTING.BG,
                      color: IDEAS_STATE_COLORS.IMPLEMENTING.COLOR,
                    }}
                  >
                    <LoaderContainer fetching={fetching} size="1rem">
                      Patvirtinti
                    </LoaderContainer>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CommissionVerdict;
