import { Grid } from "@material-ui/core";
import React from "react";

import IdeaCard from "../../ideaCard";

const GroupedIdeasMapper = ({ ideas, state, setSelectedIdea }) => {
  if (!ideas?.[state]) {
    return null;
  }

  return (
    <Grid container spacing={1}>
      {ideas?.[state]?.map((idea) => (
        <Grid item xs={12}>
          <IdeaCard setSelectedIdea={setSelectedIdea} key={idea.id} {...idea} />
        </Grid>
      ))}
    </Grid>
  );
};

export default GroupedIdeasMapper;
