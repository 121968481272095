import { combineReducers } from "redux";
import fetchReducer from "../common/fetch";
import createFilterReducer from "../common/filter";
import createNamedWrapperReducer from "../common/utils";
import moment from "moment";

const filterReducer = createFilterReducer({
  createdAt: moment().subtract(1, "month").toDate(),
});

export default combineReducers({
  list: createNamedWrapperReducer(fetchReducer, "list-ideas"),
  filter: createNamedWrapperReducer(filterReducer, "list-ideas-filter"),
});
