export const Evaluation = {
  REJECT: 1,
  ADJUST: 2,
  ACCEPT: 3,
};

export const EVALUATIION_STATE_COLORS = {
  REJECT: '#f44336',
  ADJUST: '#ffc107',
  ACCEPT: '#4caf50',
}