import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getUserId } from "selectors/user.selector";
import { pathEq } from "ramda";
import { changeIdeaState } from "reducers/ducks/idea/changeIdeaState";
import { IDEAS_STATE } from "common/ideasState";
import { listIdeas } from "reducers/ducks/idea/listIdeas";

const Assignee = ({ closeModal, idea }) => {
  const userId = useSelector(getUserId);
  const dispatch = useDispatch();

  if (pathEq(['assignee', 'id'], userId, idea) && idea?.state === IDEAS_STATE.IMPLEMENTING) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button fullWidth color="primary" variant="contained" onClick={() => {
            dispatch(changeIdeaState(idea.id, IDEAS_STATE.IMPLEMENTED))
              .then(() => dispatch(listIdeas()))
              .then(() => closeModal());
          }}>Įgyvendinta</Button>
        </Grid>
      </Grid>
    )
  }

  return null
}

export default Assignee;