import React, { useEffect } from "react";
import { Button, Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import selectUser from "shared/components/select-user";
import { useDispatch, useSelector } from "react-redux";
import { fetchCommissionMembers } from "reducers/ducks/commission/commissionMembers";
import { saveCommission } from "reducers/ducks/commission/common";
import { getCompanyCommissionMembers } from "selectors/commission";
import PageHeader from "shared/components/pageHeader";
import { createRequestMessageHandler } from "shared/utils/httpRequestMessageHandler";
import { useAccess, WithRbac } from "common/rbac";
import { ROLES } from "common/roles";

const ViewCommission = () => {
  const dispatch = useDispatch();
  const commission = useSelector(getCompanyCommissionMembers);
  useEffect(() => {
    dispatch(fetchCommissionMembers);
  }, [dispatch]);

  const canEdit = useAccess(ROLES["COMMISSION-EDIT"]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader title="Komisija" />
      </Grid>
      <Grid item xs={12} md={5}>
        <div style={{ padding: 16 }}>
          <Card>
            <CardHeader title="Komisija" />
            <CardContent>
              <Formik
                initialValues={commission}
                enableReinitialize
                onSubmit={(vals) => {
                  dispatch(saveCommission(vals))
                    .then((response) => {
                      dispatch(
                        createRequestMessageHandler([
                          {
                            type: "success",
                            title: {
                              key: "notifications.success.updatedCommission",
                            },
                            predicate() {
                              return true;
                            },
                          },
                        ])(response)
                      );
                    })
                    .catch((error) =>
                      dispatch(createRequestMessageHandler([])(error))
                    );
                }}
              >
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        name="president"
                        component={selectUser}
                        disabled={!canEdit}
                        isDisabled={!canEdit}
                        label="Pirmininkas"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        isMulti
                        name="members"
                        disabled={!canEdit}
                        isDisabled={!canEdit}
                        component={selectUser}
                        label="Nariai"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <WithRbac action={ROLES["COMMISSION-EDIT"]}>
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                        >
                          Saugoti
                        </Button>
                      </WithRbac>
                    </Grid>
                  </Grid>
                </Form>
              </Formik>
            </CardContent>
          </Card>
        </div>
      </Grid>
    </Grid>
  );
};

export default ViewCommission;
