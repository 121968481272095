import React from 'react';
import { path } from 'ramda';

import { Select } from 'shared/components/select';
import { createField } from './selectField';
import { useDispatch } from 'react-redux';
import { fetchRoles } from 'reducers/ducks/role';
import { useTranslate } from './translate';

export const SelectRole = (props) => {
  const dispatch = useDispatch();
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const translate = useTranslate();

  React.useEffect(() => {
    setLoading(true);
    dispatch(fetchRoles)
      .then(response => setOptions(response.data))
      .finally(() => setLoading(false));
  }, [dispatch, setOptions, setLoading]);

  return (
    <Select
      {...props}
      isLoading={loading}
      options={options}
      getOptionValue={path(['id'])}
      getOptionLabel={opt => translate(`enums.roles.${opt.name}`)}
      noOptionsMessage={() => translate("common.noRecords")}
      menuPlacement='bottom'
      menuPosition='fixed' />
  );
}

export default createField(SelectRole)