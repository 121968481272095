import React, { useEffect, useState } from "react";
import { Avatar, Button, Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { isUserCommissionMember } from "selectors/user.selector";
import CommissionEvaluations from "./commissionEvaluations";
import { createIdeaEvaluations } from "reducers/ducks/ideaEvaluation/createIdeaEvaluation";
import LoaderContainer from "shared/components/loaderContainer";
import { getCanEvaluateIdea } from "reducers/ducks/ideaEvaluation/getIdeaEvaluations";
import CommissionIdeaComments from "../comment/commissionComments";
import { Field, Form, Formik } from "formik";
import InputField from "shared/components/input";
import { Evaluation } from "shared/enums/evaluationType";
import { IDEAS_STATE_COLORS } from "shared/enums/ideasStateColors";
import { EVALUATIION_STATE_COLORS } from "shared/enums/evaluationType";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  comment: yup.string().required(),
});

const CommissionMember = ({ idea, closeModal }) => {
  const dispatch = useDispatch();
  const [canEvaluate, setCanEvaluate] = useState(false);
  const [fetching, setFetching] = useState(false);
  const id = idea?.id;

  useEffect(() => {
    if (id) {
      setFetching(true);
      dispatch(getCanEvaluateIdea(id))
        .then((response) => setCanEvaluate(response?.data?.canEvaluate))
        .finally(() => setFetching(false));
    }
  }, [id]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CommissionEvaluations idea={idea} />
      </Grid>

      {
        <LoaderContainer fetching={fetching}>
          {!!canEvaluate && (
            <Grid item xs={12}>
              <Formik validationSchema={validationSchema} initialValues={{}}>
                {({ validateForm, isValid, setFieldTouched }) => (
                  <Form>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">Vertinimas</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="comment"
                          required
                          component={InputField}
                          fullWidth
                          label="Vertintojo komentaras"
                          InputProps={{ multiline: true, rows: 4 }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field name="comment">
                          {({ field }) => (
                            <Grid container spacing={1}>
                              <Grid item xs={4}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="secondary"
                                  style={{
                                    backgroundColor:
                                      EVALUATIION_STATE_COLORS.REJECT,
                                    color: "#000",
                                  }}
                                  onClick={() => {
                                    if (!isValid) {
                                      return setFieldTouched(
                                        "comment",
                                        true,
                                        true
                                      );
                                    }
                                    dispatch(
                                      createIdeaEvaluations({
                                        idea,
                                        evaluation: Evaluation.REJECT,
                                        comment: field.value,
                                      })
                                    ).then(() => closeModal());
                                  }}
                                >
                                  Atmesti
                                </Button>
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="secondary"
                                  style={{
                                    backgroundColor:
                                      EVALUATIION_STATE_COLORS.ADJUST,
                                    color: "#000",
                                  }}
                                  onClick={() => {
                                    if (!isValid) {
                                      return setFieldTouched(
                                        "comment",
                                        true,
                                        true
                                      );
                                    }
                                    dispatch(
                                      createIdeaEvaluations({
                                        idea,
                                        evaluation: Evaluation.ADJUST,
                                        comment: field.value,
                                      })
                                    ).then(() => closeModal());
                                  }}
                                >
                                  Patikslinti
                                </Button>
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="primary"
                                  style={{
                                    backgroundColor:
                                      EVALUATIION_STATE_COLORS.ACCEPT,
                                    color: "#000",
                                  }}
                                  onClick={() => {
                                    dispatch(
                                      createIdeaEvaluations({
                                        idea,
                                        evaluation: Evaluation.ACCEPT,
                                        comment: field.value,
                                      })
                                    ).then(() => closeModal());
                                  }}
                                >
                                  Patvirtinti
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          )}
        </LoaderContainer>
      }
    </Grid>
  );
};

export default CommissionMember;
