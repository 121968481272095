import React from "react";
import { Field } from "formik";
import { Checkbox, Grid, Tooltip } from "@material-ui/core";
import { path } from "ramda";

import InputField from "shared/components/input";
import InputWithRecomendation from "shared/components/inputWithRecomendation";
import CheckboxGroup from "shared/components/checkboxGroup";
import { useTranslate } from "shared/components/translate";
import GroupCheckbox from "shared/components/checkboxGroup/groupCheckbox";
import { useFetchEliminateLosses } from "shared/components/selectEliminateLosses/hooks";
import LoaderContainer from "shared/components/loaderContainer";
import { useFetchImproveAreas } from "shared/components/selectImproveAreas/hooks";
import { Info } from "@material-ui/icons";

const IdeaForm = ({ disabled = false }) => {
  const translate = useTranslate();
  const [fetchingOptions, eliminateLosses] = useFetchEliminateLosses();
  const [fetchingImproveAreas, improveAreas] = useFetchImproveAreas();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Field
          name="currentSituation"
          fullWidth
          component={InputField}
          InputProps={{ rows: 4, multiline: true, readOnly: disabled }}
          label="Esama situacija"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          name="problem"
          InputProps={{ rows: 4, multiline: true, readOnly: disabled }}
          component={InputWithRecomendation}
          rowSpan={4}
          label="Problema"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          name="suggestedSolution"
          component={InputField}
          rowSpan={4}
          InputProps={{ rows: 4, multiline: true, readOnly: disabled }}
          label="Siūlomas sprendimas"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          disabled={disabled}
          name="eliminateLosses"
          component={CheckboxGroup}
          label="Šalina nuostolius:"
          valueField={path(["id"])}
        >
          <LoaderContainer fetching={fetchingOptions}>
            {eliminateLosses.map((loss) => {
              return (
                <Grid container alignItems="center">
                  <Grid item>
                    <GroupCheckbox
                      key={loss.id}
                      label={translate(`enums.eliminateLosses.${loss?.name}`)}
                      value={loss}
                    />
                  </Grid>
                  <Grid item>
                    <Tooltip title={translate(`enums.eliminateLossesExplanation.${loss?.name}`)}>
                      <Info color="disabled" />
                    </Tooltip>
                  </Grid>
                </Grid>
              );
            })}
          </LoaderContainer>
        </Field>
      </Grid>
      <Grid item xs={12}>
        <Field
          disabled={disabled}
          name="improveAreas"
          component={CheckboxGroup}
          label="Pagerina:"
          valueField={path(["id"])}
        >
          <LoaderContainer fetching={fetchingImproveAreas}>
            <Grid container>
              {improveAreas.map((area) => {
                return (
                  <Grid item xs={12}>
                    <Grid container alignItems="center" key={area.id}>
                      <Grid item>
                        <GroupCheckbox
                          label={translate(`enums.improveAreas.${area?.name}`)}
                          value={area}
                        />
                      </Grid>
                      <Grid item>
                        <Tooltip title={translate(`enums.improveAreasExplanation.${area?.name}`)}>
                          <Info color="disabled" />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </LoaderContainer>
        </Field>
      </Grid>
      <Grid item xs={12}>
        <Field
          variant="radio"
          disabled={disabled}
          name="implementYourself"
          component={CheckboxGroup}
          label="Idėją įgyvendinsiu pats"
        >
          <Grid container>
            <Grid item>
              <GroupCheckbox label="Taip" value="true" />
            </Grid>
            <Grid item>
              <GroupCheckbox label="Ne" value="false" />
            </Grid>
          </Grid>
        </Field>
      </Grid>
    </Grid>
  );
};

export default IdeaForm;
