import { groupBy, path } from "ramda";
import { createSelector } from "reselect";

const emptyList = [];
const emptyArray = {};

export const getListedIdeas = (state) => state?.ideas?.list?.data || emptyList;
export const getIdeasFilter = state => state?.ideas?.filter || emptyArray;

export const getGroupedIdeasByDepartment = createSelector(
  getListedIdeas,
  (ideas) => {
    return groupBy(path(["department", "id"]), ideas);
  }
);
