import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AuthLayout from 'shared/layouts/auth-layout';
import Auth from '../components/auth/login';
import Company from './company.routes';
import ForgotPassword from '../components/auth/forgotPassword';
import ForgotPasswordSuccess from '../components/auth/forgotPassword.success';
import ChangePassword from '../components/auth/changePassword';
import CreatePassword from '../components/auth/createPassword';

export default (props) => {
  return (
    <AuthLayout>
      <Switch>
        <Route
          path={`${props.match.url}/create`}
          render={Company} />
        <Route
          path={`${props.match.url}/changePassword/:key`}
          render={ChangePassword} />
        <Route
          path={`${props.match.url}/createPassword/:key`}
          render={CreatePassword} />
        <Route
          path={`${props.match.url}/forgotPassword/success`}
          render={ForgotPasswordSuccess} />
        <Route
          path={`${props.match.url}/forgotPassword`}
          exact
          render={ForgotPassword} />
        <Route
          path={`${props.match.url}/login`}
          component={Auth} />
      </Switch>
    </AuthLayout>
  );
}