import React from "react";

import { createField } from "./selectField";
import { Select } from "shared/components/select";
import { useTranslate } from "./translate";
import { IDEAS_STATE } from "common/ideasState";

export const options = [
  { value: IDEAS_STATE.SUGGESTED },
  { value: IDEAS_STATE.ADJUSTMENT },
  { value: IDEAS_STATE.EVALUATED },
  { value: IDEAS_STATE.IMPLEMENTING },
  { value: IDEAS_STATE.IMPLEMENTED },
  { value: IDEAS_STATE.REJECTED },
];

const SelectIdeaStates = (props) => {
  const translate = useTranslate();

  return (
    <Select
      {...props}
      options={options}
      getOptionValue={(val) => val?.value}
      getOptionLabel={(val) => translate(`enums.ideaStates.${val.value}`)}
      menuPlacement="bottom"
      menuPosition="fixed"
    />
  );
};

export default createField(SelectIdeaStates);
