import React from 'react';
import { Formik } from 'formik';
import { Grid, Card, CardContent, CardActions, Button, Link } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { compose, path } from 'ramda';
import { withRouter, Link as RouterLink } from 'react-router-dom';

import PageHeader from '../../../shared/components/pageHeader';
import { withTranslate } from '../../../shared/components/translate';
import BasicInfo from '../form/basicInfo';
import AuthInfo from '../form/authInfo';
import RightsInfo from '../form/rights';
import { validationSchema } from '../form/validation';
import { inviteUser } from 'reducers/ducks/user/inviteUser';

const Create = (compProps) => {
  const dispatch = useDispatch();

  return (
    <Grid container>
      <Grid item container>
        <PageHeader title={compProps.translate("users.create.title")} />
      </Grid>
      <Grid item xs={12} md={5}>
        <div style={{ padding: 16 }}>
          <Formik
            validationSchema={validationSchema}
            initialValues={{
              active: true
            }}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              setSubmitting(true);
              dispatch(inviteUser(values))
                .then(() => compProps.history.push('/users'))
                .catch(err => {
                  const errors = path(['response', 'data', 'errors'], err)
                  if (errors) setErrors(errors);
                })
                .finally(() => setSubmitting(false));
            }}
            render={props => (
              <form onSubmit={props.handleSubmit}>
                <Card>
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <BasicInfo />
                      </Grid>
                      <Grid item xs={12}>
                        <RightsInfo />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Link component={RouterLink} to='/users'>
                      <Button>{compProps.translate("common.cancel")}</Button>
                    </Link>
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                      disabled={!props.isValid}
                    >
                      {compProps.translate("common.create")}
                    </Button>
                  </CardActions>
                </Card>
              </form>
            )}
          />
        </div>
      </Grid>
    </Grid>

  )
}

export default compose(
  withTranslate,
  withRouter,
)(Create);