import React from 'react';
import { Grid, Table, TableCell, TableHead, TableRow, TableBody, Button, Link, Card, CardContent } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { v1 as uuid } from 'uuid';

import PageHeader from 'shared/components/pageHeader';
import { WithRbac } from 'common/rbac';
import { fetchRoleGroups } from 'reducers/ducks/roleGroup';
import MoreButton from 'shared/components/moreButton';
import { ROLES } from 'common/roles';
import { goTo } from 'reducers/ducks/common/goTo';
import DeleteDialog from './deleteDialog';
import { useTranslate } from 'shared/components/translate';

const RoleGroupContainer = () => {
  const dispatch = useDispatch();
  const [roleGroups, setRoleGroups] = React.useState([]);
  const [data, setData] = React.useState(null);
  const [fetchId, setFetchId] = React.useState(null);
  const translate = useTranslate();

  const reloadList = () => setFetchId(uuid());
  React.useEffect(() => {
    dispatch(fetchRoleGroups)
      .then(response => setRoleGroups(response.data))
  }, [dispatch, setRoleGroups, fetchId]);

  const handleClick = (roleGroup) => (eventType) => {
    switch (eventType) {
      case 'delete':
        return setData(roleGroup)
      default:
        return goTo(`/roleGroup/edit/${roleGroup.id}`);
    }
  }

  return (
    <>
      <DeleteDialog reloadList={reloadList} data={data} handleClose={() => setData(null)} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PageHeader title={translate("roleGroup.title")}>
            {/* <WithRbac action={ROLES["ROLE-GROUPS-CREATE"]}> */}
              <Link to='/roleGroup/create' component={RouterLink}>
                <Button
                  variant='contained'
                  color='primary'
                >
                  {translate("roleGroup.create.action")}
                </Button>
              </Link>
            {/* </WithRbac> */}
          </PageHeader>
        </Grid>
        <Grid item xs={12}>
          <div style={{ padding: 16 }}>
            <Card>
              <CardContent style={{ overflow: 'auto' }}>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell>{translate("roleGroup.table.name")}</TableCell>
                      <TableCell>{translate("roleGroup.table.description")}</TableCell>
                      <TableCell >{translate("roleGroup.table.assignedRoles")}</TableCell>
                      <WithRbac action={ROLES["ROLE-GROUPS-UPDATE"]}>
                        <TableCell>{translate("roleGroup.table.actions")}</TableCell>
                      </WithRbac>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      roleGroups.map(group => (
                        <TableRow>
                          <TableCell>{group.company_id == null ? translate(`enums.roleGroups.${group.name}`) : group.name }</TableCell>
                          <TableCell>{group.company_id == null ? translate(`enums.roleGroups.${group.description}`) : group.description }</TableCell>
                          <TableCell>{group.roles.map(role => translate(`enums.roles.${role.name}`)).join(', ')}</TableCell>
                          <WithRbac action={ROLES["ROLE-GROUPS-UPDATE"]}>
                            <TableCell>
                              {!!group.company_id && (

                                <MoreButton
                                  main={{
                                    event: 'edit',
                                    title: translate("common.edit")
                                  }}
                                  options={[
                                    {
                                      event: 'delete',
                                      title: translate("common.delete"),
                                      action: ROLES["ROLE-GROUPS-DELETE"]
                                    }
                                  ]}
                                  handleClick={handleClick(group)}
                                />
                              )}
                            </TableCell>
                          </WithRbac>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default RoleGroupContainer;