import React from "react";
import { Avatar, Grid, Paper, Typography } from "@material-ui/core";
import moment from "moment";

const CommentBox = ({ comment }) => {
  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={12}>
          <Paper elevation={1} style={{ minHeight: 45, padding: 16 }}>
            <Grid container spacing={2} wrap="nowrap">
              <Grid item>
                <Avatar variant="circle" />
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      {comment?.createdBy?.firstName}{" "}
                      {comment?.createdBy?.lastName}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1">{comment.comment}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Grid item>
              <Typography variant="caption" align="right">
                {moment(comment.createdAt).format("YYYY-MM-DD")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CommentBox;
