import React from "react";
import { Route, Switch } from "react-router-dom";

import viewCommission from 'components/commission/viewCommission';

export default (props) => {
  return (
    <Switch>
      <Route
        path={`${props.match.url}`}
        component={viewCommission} />
    </Switch>
  )
}


