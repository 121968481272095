import React from "react";

import ContentSwitch from './switchContent';

const GroupGrid = (props) => {
  return (
    <ContentSwitch {...props} />
  );
};

export default GroupGrid;
